export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/kyc-sign-up',
    name: 'kyc-sign-up',
    component: () => import('@/views/pages/authentication/KYCSignUp.vue'),
    meta: {
      layout: 'full',
      // resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/v1/advisor/subscriptions/:packageID/:optionID/pay',
    name: 'web-view',
    component: () => import('@/views/pages/authentication/WebView.vue'),
    meta: {
      layout: 'full',
      // resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('@/views/pages/authentication/SignUp.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
]
