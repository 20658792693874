export default [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/apps/home/Index.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/recommendation',
    name: 'recommendation',
    component: () => import('@/views/apps/recommendation/Index.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('@/views/apps/clients/Index.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/public/:advisorID/pkg',   
    name: 'public-packages',
    component: () => import('@/views/pages/authentication/PublicPackages.vue'),
    meta: {
      layout: 'full',
      // resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/public/stock-advisor',   
    name: 'public-packages',
    component: () => import('@/views/pages/authentication/StockAdvisor.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/pakages',
    name: 'pakages',
    component: () => import('@/views/apps/pakages/Index.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/apps/transactions/Index.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/apps/profile/Index.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },
]
