export default {
  namespaced: true,
  state: () => ({
    authUsers: null,
    authToken: null,
  }),
  getters: {
    getAuthUsers: (state) => {
      return state.authUsers;
    },
    getAuthToken: (state) => {
      return state.authToken;
    },
  },
  mutations: {
    setLoginUsers(state, {data}) {
      state.authUsers = data;
      state.authToken = data.token;
    },
    removeLoginUsers(state) {
      state.authUsers = null;
      state.authToken = null;
    }
  },
  actions: {
    login(context, payload) {
      context.commit("setLoginUsers", payload);
    },
    logout(context) {
      context.commit("removeLoginUsers");
    },
  },
};
