import Vue from 'vue'
// import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import BootstrapVue from "bootstrap-vue";
import {provide} from '@vue/composition-api'
import router from './router'
import store from './store'
import App from './App.vue'
import axios from '@/libs/axios';
import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full";
import * as rules from "vee-validate/dist/rules";
// Global Components
import './global-components'
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);


Vue.use(BootstrapVue);

// 3rd party plugins
// import '@axios'
import '@/libs/acl'
import '@/libs/toastification'
import '@/libs/vue-select'

// Composition API
// Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
import '@/assets/scss/style.scss'

Vue.config.productionTip = false
const user = JSON.parse(localStorage.getItem('userData'))

new Vue({
  router,
  store,
  setup() {
    provide("$http", axios);
  },
  render: h => h(App),
}).$mount('#app')
