import Vue from 'vue'
import Vuex from 'vuex'
// import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";


// Modules
import app from './app'
import auth from './app/auth'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import KYCSignUp from './kyc-sign-up'

Vue.use(Vuex)

export default new Vuex.Store({
  // export default createStore({
  modules: {
    app,
    auth,
    appConfig,
    verticalMenu,
    KYCSignUp,
  },
  plugins: [createPersistedState(
    {
      paths: ['KYCSignUp', 'auth'],
    }
  )],
  strict: process.env.DEV,
})
