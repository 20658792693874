import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import dashboard from './routes/dashboard'
import authPages from './routes/auth-pages'

Vue.use(VueRouter)

import store from '../store'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...dashboard,
    ...authPages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  // const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
  }

  if(to.meta.redirectIfLoggedIn == true && !store.state.auth.authToken) {
    return next({ name: 'login' })
  }

  if (to.name == 'login' && to.meta.redirectIfLoggedIn == false && store.state.auth.authToken) {
    return next({ name: 'home' })
  }
  // if (to.meta.redirectIfLoggedIn) {
  //   if (authToken && to.name != 'login') {
  //     return next();
  //   }
  // }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
