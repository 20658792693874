export default {
  namespaced: true,
  state:()=> ({
      users: [
        { "firstName": "Olivia", "lastName": "Rhye", "email":"olivia@gmail.com", "phone": 7895462781, "kyc": true, "status": true },
      ],
      subscriptionDetails: null
    }),
    getters: {
      getUsers: (state) => {
        return state.users;
      },
      getSubscriptionDetails: (state) => {
        return state.subscriptionDetails;
      },
    },
    mutations: {
      setUsers(state, { data }) {
        const count = state.users.length
        data.id = count+1;
        state.users.push(data);
      },
      storeSubscriptionDetails(state, {data}) {
        state.subscriptionDetails = data;
      },
    },
    actions: {
      addUser(context, payload) {
        context.commit('setUsers', payload);
      },
      storeSubscriptionDetails(context, payload) {
        context.commit("storeSubscriptionDetails", payload);
      },
    },
  };
  